import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import { Button, Modal } from "react-bootstrap";
import Obfuscate from 'react-obfuscate';

import EBLogo from "../../../vendor/svgs/EBLOGO.svg";
import EBLogoText from "../../../vendor/svgs/EBLOGOTEXTONLY.svg";


const Header = ({ onNote }) => {
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
      variablesJson {
        alert_header
      }
    }
  `)

  const index = queryData.localSearchPages.index
  const store = queryData.localSearchPages.store

  const alert_header_inner_html = { "__html": queryData.variablesJson.alert_header }

  const [query, setQuery] = useState('')
  const [displayQuery, setDisplayQuery] = useState('')
  const results = useFlexSearch(query, index, store)

  const handleOnQueryChange = e => {
    // only pass queries that are at least 2 letters
    if (e.target.value.length > 1) {
      setQuery(e.target.value)
      setDisplayQuery(e.target.value)
    } else {
      setDisplayQuery(e.target.value)
      setQuery('')
    }
  };

  const [modalContact, setModalContact] = useState(false);
  const toggleContactModal = () => {
    if (modalContact) {
      setModalContact(false)
    } else {
      setModalContact(true)
    }
  };

  const us_territories = ['AS', 'DC', 'GU', 'MP', 'PR', 'VI', 'DK', 'OL', 'PI']

  // console.log(results)
  return (
    <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 px-0 bg-white">
          <div className="row ml-3 mr-3">
            <div className="bg-white col-xl-3 col-xxl-3 col-lg-3 col-md-4 col-sm-12 pl-4 pr-1 pt-1 pb-lg-1 pb-sm-1 pb-1 d-block align-middle">
              <Link to="/" className="brand-logo" style={{'lineHeight': '4em'}}>
                <EBLogo className="logo-abbr mr-1" style={{'height': 84}}/>
                <EBLogoText className="logo-compact mt-5 mb-2" style={{'height': 42}}/>
              </Link>
            </div>
            <Modal className="fade" show={modalContact} onHide={toggleContactModal}>
              <Modal.Header>
                <Modal.Title className="text-black"><i className="las la-envelope-open scale5 mr-1"></i> Get In Touch</Modal.Title>
                <Button
                  onClick={() => setModalContact(false)}
                  variant=""
                  className="close"
                >
                  <span>&times;</span>
                </Button>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <strong>If you have an idea for a new feature, want to report a bug, or just want to chat, please reach out!</strong> 
                </p>
                <p>
                  <i className="lab la-github scale5 mr-1"></i> We use <a href="https://github.com/ElectBitcoin/ElectBitcoin/discussions" target="_blank" className="text-primary">GitHub Discussions</a> for general discussion, bug reports, and sharing ideas. If you do not already have a GitHub account, creating one is fast and free.
                </p>
                <p>
                  <i className="lab la-twitter scale5 mr-1"></i> We share important updates, announcements and log all new record entries on <a href="https://twitter.com/ElectBitcoinOrg" target="_blank" className="text-primary">Twitter: @ElectBitcoinOrg</a>.
                </p>
                <p>
                  <i className="mdi mdi-email scale4 mr-1"></i> Finally, you can also reach us by email: <Obfuscate style={{ display: 'inline' }} className="text-primary" email="mail@ElectBitcoin.org"/> (<a href="/m-electbitcoin-org.asc" target="_blank" className="text-primary">PGP Public Key here</a>)
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="rounded" variant="primary" onClick={() => setModalContact(false)}>OK</Button>
              </Modal.Footer>
            </Modal>
            <div className="bg-white col-xl-5 col-xxl-5 col-lg-4 col-md-8 col-sm-12 d-block pl-1 pr-1 pt-1 pt-lg-3 pt-md-4 pt-sm-1 pb-lg-3 pb-sm-1 text-center">
              <div className="header-left m-auto">
                <div className="search_bar" style={{'lineHeight': '4em'}}>
                  <input
                    className="form-control form-control-sm"
                    type="search"
                    placeholder="Search by Name or by State"
                    aria-label="Search"
                    data-toggle="dropdown"
                    name="query"
                    id="search"
                    value={displayQuery}
                    onChange={(event) => handleOnQueryChange(event)}
                  />
                  <span
                    className="search_icon p-3"
                    data-toggle="dropdown"
                  >
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                        fill="#3B4CB8"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="show dropdown">
                {results.length ? (
                  <div x-placement="bottom-start" aria-labelledby="" className="dropdown-menu show rounded-bottom" style={{position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 0px)'}} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-start">
                    <h5 className="dropdown-header">{results.length} pages(s) matched your query</h5>
                    <ul>
                      {results.map(result => (
                        <>
                          {result.person_name_common_full.length ? (
                            <li key={result.slug} className="dropdown-item">
                              <Link to={`${result.slug}/`}>{result.person_name_common_full} - {result.person_role}</Link>
                            </li>
                          ) : us_territories.includes(result.state) ? (
                            <li key={result.slug} className="dropdown-item">
                              <Link to={`${result.slug}/`}>{result.state_name}</Link>
                            </li>
                          ) : (
                            <li key={result.slug} className="dropdown-item">
                              <Link to={`${result.slug}/`}>The State of {result.state_name}</Link>
                            </li>
                          )}
                        </>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <>
                    {query.length ? (
                      <div x-placement="bottom-start" aria-labelledby="" className="dropdown-menu show rounded" style={{position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 0px)'}} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-start">
                        <h5 className="dropdown-header">Sorry, no matches found.</h5>
                      </div>
                    ) : ('')}
                  </>
                )}
              </div>
            </div>
            <div className="bg-white col-xl-4 col-xxl-4 col-lg-5 col-md-12 col-sm-12 d-flex pl-3 pr-1 pt-xxl-4 pt-xl-4 pt-lg-4 pt-sm-1 pb-lg-3 pb-sm-1 text-center align-middle justify-content-between" style={{'lineHeight': '4em'}}>
              <Link to="/about/#donate" className="text-primary"><i className="las la-donate scale3"></i><strong> Support Us</strong></Link>
              <Link to="/about/#get-involved" className="text-primary"><i className="las la-users scale3"></i><strong> Get Involved</strong></Link>
              <Link to="#" className="text-primary" onClick={() => setModalContact(true)}><i className="mdi mdi-email scale2"></i></Link>
              <a href="https://twitter.com/ElectBitcoinOrg" className="text-primary" target="_blank"><i className="lab la-twitter scale3"></i></a>
              <a href="https://github.com/ElectBitcoin/ElectBitcoin" className="text-primary" target="_blank"><i className="lab la-github scale3"></i></a>
            </div>
          </div>
          <div dangerouslySetInnerHTML={alert_header_inner_html} />
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
