import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Button, Modal } from "react-bootstrap";
import Obfuscate from 'react-obfuscate';
import BitcoinSVG from "../../vendor/svgs/Bitcoin.svg";

const Footer = () => {
  const [modalContact, setModalContact] = useState(false);
  const toggleContactModal = () => {
    if (modalContact) {
      setModalContact(false)
    } else {
      setModalContact(true)
    }
  };

  return (

	    <div className="footer">
        <Modal className="fade" show={modalContact} onHide={toggleContactModal}>
          <Modal.Header>
            <Modal.Title className="text-black"><i className="las la-envelope-open scale5 mr-1"></i> Get In Touch</Modal.Title>
            <Button
              onClick={() => setModalContact(false)}
              variant=""
              className="close"
            >
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>If you have an idea for a new feature, want to report a bug, or just want to chat, please reach out!</strong> 
            </p>
            <p>
              <i className="lab la-github scale5 mr-1"></i> We use <a href="https://github.com/ElectBitcoin/ElectBitcoin/discussions" target="_blank" className="text-primary">GitHub Discussions</a> for general discussion, bug reports, and sharing ideas. If you do not already have a GitHub account, creating one is fast and free.
            </p>
            <p>
              <i className="lab la-twitter scale5 mr-1"></i> We share important updates, announcements and log all new record entries on <a href="https://twitter.com/ElectBitcoinOrg" target="_blank" className="text-primary">Twitter: @ElectBitcoinOrg</a>.
            </p>
            <p>
              <i className="mdi mdi-email scale4 mr-1"></i> Finally, you can also reach us by email: <Obfuscate style={{ display: 'inline' }} className="text-primary" email="mail@ElectBitcoin.org"/> (<a href="/m-electbitcoin-org.asc" target="_blank" className="text-primary">PGP Public Key here</a>)
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded" variant="primary" onClick={() => setModalContact(false)}>OK</Button>
          </Modal.Footer>
        </Modal>
	      <div className="copyright text-white pb-0 px-0">
	        <p className="fs-14 d-none d-sm-block">
	          Proudly Built with <BitcoinSVG height="20" width="20" className="mb-1"/> in The United States of America <span className="mt-1">🇺🇸</span>
          </p>
          <p className="fs-13 d-block d-sm-none">
	          Proudly Built with <BitcoinSVG height="20" width="20" className="mb-1"/> in The USA <span className="mt-1">🇺🇸</span>
          </p>
          <p>
            <a href="https://twitter.com/ElectBitcoinOrg" className="text-white mx-5" target="_blank"><i className="lab la-twitter scale5"></i></a>
            <a href="https://github.com/ElectBitcoin/ElectBitcoin" className="text-white mx-5" target="_blank"><i className="lab la-github scale5"></i></a>
          </p>
          <p className="fs-14 d-none d-sm-block">
            <Link to="/about/" className="text-white mr-2 mr-sm-4"><i className="las la-info-circle scale3"></i> About Us</Link>
            <Link to="/about/#donate" className="text-white mx-2 mx-sm-4"><i className="las la-donate scale3"></i> Donate</Link>
            <Link to="/about/#get-involved" className="text-white mx-2 mx-sm-4"><i className="las la-users scale3"></i> Get Involved</Link>
            <Link to="#" className="text-white ml-2 ml-sm-4" onClick={() => setModalContact(true)}><i className="las la-envelope-open scale3"></i> Contact</Link>
          </p>
          <p className="fs-12 d-block d-sm-none">
            <Link to="/about/" className="text-white mr-2 mr-sm-4"><i className="las la-info-circle"></i> About Us</Link>
            <Link to="/about/#donate" className="text-white mx-2 mx-sm-4"><i className="las la-donate"></i> Donate</Link>
            <Link to="/about/#get-involved" className="text-white mx-2 mx-sm-4"><i className="las la-users"></i> Get Involved</Link>
            <Link to="#" className="text-white ml-2 ml-sm-4" onClick={() => setModalContact(true)}><i className="las la-envelope-open"></i> Contact</Link>
          </p>
	      </div>
	    </div>
  );
};



export default Footer;
